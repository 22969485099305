import wsTrackNr from './wsTrackNr';
import getElementAttributes from './getElementAttributes';
import getWidgetHeading from './getWidgetHeading';
import findWidgetName from './findWidgetName';
import findWidgetId from './findWidgetId';
import getCommonData from './getCommonData';
import isExternalUrl from './isExternalUrl';
import getSection from './getSection';
import findDialogTitle from './findDialogTitle';
/**
 * Calls the DDC.pushToGtmDataLayer function on the win object
 * @param {Object} win global window object
 * @param {String} eventType name of the event
 * @param {Object} eventData properties to send for the event
 * @param {Set} eventTypes Set of event types that happend during the same browser event
 */
const pushEvent = (win, eventObject, eventTypes) => {
	if (eventObject) {
		const { eventType, eventData, overrides = {} } = eventObject;
		const hasEventData = eventData && !!Object.keys(eventData).length;
		const element =
			win?.jQuery && eventData?.element instanceof win.jQuery
				? eventData.element[0]
				: eventData?.element;

		const attributes = getElementAttributes(element);
		const widgetHeading = getWidgetHeading(element);
		const widgetName = findWidgetName(element);
		const widgetId = findWidgetId(element);
		const externalUrl = isExternalUrl(
			window?.location?.host,
			element?.host,
			element?.href
		);
		const trackingSection = getSection(element, win.DDC);
		const dialogTitle = findDialogTitle(element);

		const isTelephonicUrl = element?.href?.startsWith('tel:');

		let isChecked;
		if (element && element.type === 'checkbox') {
			isChecked = element.checked ? 'checked' : 'unchecked';
		}

		const eventTypesForSameAction =
			hasEventData && eventTypes
				? Array.from(eventTypes)
						.filter(
							(eventTypeToRemove) =>
								eventType !== eventTypeToRemove
						)
						.join(',')
				: undefined;

		wsTrackNr(eventType);

		const payload = {
			event: eventType,
			// only output this stuff if there is eventData
			// any event not passing eventData prior to this pushData function being made was not getting these properties
			...(hasEventData && {
				eventData: {
					...eventData,
					// TODO: Find where things use event/eventName/eventType and see if we can have them all use the same variable
					event: eventType,
					eventName: overrides.eventName || eventType,
					eventType: overrides.eventType || eventType,
					...(eventTypesForSameAction && {
						eventTypesForSameAction
					}),
					...(Object.keys(attributes).length && {
						attributes
					}),
					...(widgetHeading && { widgetHeading }),
					...(widgetName && { widgetName }),
					...(widgetId && { widgetId }),
					...(dialogTitle && { dialogTitle }),
					...(typeof externalUrl === 'boolean' && {
						externalUrl
					}),
					...(typeof isTelephonicUrl === 'boolean' && {
						isTelephonicUrl
					}),
					...(isTelephonicUrl === true && {
						phoneNumber:
							eventData.phoneNumber ||
							eventData.url?.replace('tel:', '')
					}),
					...(trackingSection && { trackingSection }),
					...(isChecked && { isChecked }),
					// adding eventData.modelYear for consistency with what we call this in eventData.vehicle.modelYear and DDC.dataLayer.vehicles
					...(eventData?.year && { modelYear: eventData.year }),
					...getCommonData(element)
				}
			}),
			// this takes care of one situation where an event (ddc.map) sends an empty object for eventData
			// making sure that stays in place to not break anything relying on it being like that
			...(!hasEventData &&
				eventData && {
					eventData
				})
		};

		win.DDC.pushToGtmDataLayer(payload);
	}
};

export default pushEvent;
